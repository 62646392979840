// ========================================================

// # OBJECT

.section {
}

.section__inner {
    padding: $gutter/2;
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    position: relative;
    background-position: bottom;
    background-size: cover;
    background-image: url('../assets/images/fox-stadion.png');
    box-shadow: 0px 0px 70px black;
}
