// ========================================================

// #CONTENT-BOX
.content-box {
	padding: $gutter/2;
}

@for $i from 0 through $multiplyGutter {
	.content-box--#{$i}x {
		padding: ($gutter/2) * $i;
	}

	// H = padding horizontal
	.content-box--h-#{$i}x {
		padding-left: ($gutter/2) * $i;
		padding-right: ($gutter/2) * $i;
	}

	// V = padding vertical
	.content-box--v-#{$i}x {
		padding-top: ($gutter/2) * $i;
		padding-bottom: ($gutter/2) * $i;
	}

	// T = padding top
	.content-box--t-#{$i}x {
		padding-top: ($gutter/2) * $i;
	}

	// R = padding right
	.content-box--r-#{$i}x {
		padding-right: ($gutter/2) * $i;
	}

	// B = padding bottom
	.content-box--b-#{$i}x {
		padding-bottom: ($gutter/2) * $i;
	}

	// L = padding bottom
	.content-box--l-#{$i}x {
		padding-left: ($gutter/2) * $i;
	}
}

@media screen and (min-width: $view-tablet-landscape) {

	.content-box\@desktop {
		padding: $gutter/2;
	}

	@for $i from 0 through $multiplyGutter {
		.content-box--#{$i}x\@desktop {
			padding: ($gutter/2) * $i;
		}

		// H = padding horizontal
		.content-box--h-#{$i}x\@desktop {
			padding-left: ($gutter/2) * $i;
			padding-right: ($gutter/2) * $i;
		}

		// V = padding vertical
		.content-box--v-#{$i}x\@desktop {
			padding-top: ($gutter/2) * $i;
			padding-bottom: ($gutter/2) * $i;
		}

		// T = padding top
		.content-box--t-#{$i}x\@desktop {
			padding-top: ($gutter/2) * $i;
		}

		// R = padding right
		.content-box--r-#{$i}x\@desktop {
			padding-right: ($gutter/2) * $i;
		}

		// B = padding bottom
		.content-box--b-#{$i}x\@desktop {
			padding-bottom: ($gutter/2) * $i;
		}

		// L = padding bottom
		.content-box--l-#{$i}x\@desktop {
			padding-left: ($gutter/2) * $i;
		}
	}
}