
,// ========================================================
// HTML and Body
// ========================================================
html,
button,
datalist,
textarea {
    color: $color-text;
}

html {
    font-style: normal;
    font-weight: normal;
    font-family: $font-khand-regular;
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 1.1;
    box-sizing: border-box;
    text-align: center;
    text-shadow: 0px 0px 8px rgba(0,0,0,0.4);
}

* {
	box-sizing: border-box;

    &:before,
    &:after {
        box-sizing: inherit;
    }
}

body {
    font-size: globalPerc($text-size);

    &:after {
        content: "0em";
        display: block;
        height: 0;
        position: absolute;
        overflow: hidden;
        width: 0;
    }
}


// ========================================================
// Headers
// ========================================================
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
    margin: 0 0 ($gutter/2) 0;
}

h1 {
    font-family: $font-sports-fox;
    text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2px;
    font-size: 30px;
    line-height: 1.2;
    width: 100%;
}

h2 {
    font-family: $font-khand-semibold;
    text-shadow: 0px 0px 8px rgba(0,50,185,1);
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2px;
    font-size: 28px;
    line-height: 1.1;
    width: 100%;
}

h3 {
    font-family: $font-khand-medium;
    text-shadow: 2px 2px 16px #5894c6;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2px;
    font-size: 25px;
    line-height: 1.1;
    width: 100%;
}

h4 {
    font-size: 1.25em;
    line-height: 1.1;
}

h5 {
    font-size: 1.1em;
    line-height: 1.1;
}

h6 {
    font-size: 1em;
    line-height: 1.1;
}



// ========================================================
// Default spacings
// ========================================================
// h2,
// h3,
// p,
// blockquote,
// img,
// table,
// form,
// fieldset,
// pre {
//     &:first-child {
//         margin-top: 0;
//     }
// }

// h2 + p,
// h3 + p,
// h2 + ol,
// h3 + ol,
// h2 + ul,
// h3 + ul {
//     margin-top: 0;
// }

// p,
// ol,
// ul,
// blockquote,
// img,
// table,
// form,
// fieldset,
// pre,
// object,
// embed,
// video,
// audio,
// figure,
// figcaption,
// .paragraph,
// h3 {
//     margin-top: $gutter;
// }

// p,
// ol,
// ul,
// blockquote,
// img,
// table,
// form,
// fieldset,
// pre,
// object,
// embed,
// video,
// audio,
// figure,
// figcaption,
// .paragraph {
//     margin-bottom: $gutter;
// }

// ol,
// ul {
//     padding-left: $gutter*2;
// }

// li {
//     margin-bottom: $gutter/2;
//     margin-top: $gutter/2;
// }

// datalist,
// input,
// select,
// textarea {
//     padding: $gutter/2 $gutter;
//     background-color: transparent;
//     color: inherit;

//     &:focus,
//     &:active {
//         color: inherit;
//     }
// }

h1,
h2,
h3,
h4,
p,
a {
    margin: $gutter/2 0;
}

p {
    width: 100%;
    text-shadow: 2px 2px 0px rgba(0,0,0,0.5);
}


// ========================================================
// Links and buttons
// ========================================================
a {
    text-decoration: underline;
    color: white;
    text-transform: uppercase;
    font-size: 20px;
    text-shadow: none;
    font-family: $font-tungsten-bold;
}

button {
    background-color: #000;
    color: #fff;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    display: inline-block;

    &:focus,
    &:hover,
    &:active {
        background-color: #000;
        color: darken(#fff, 10%);
    }
}

select {
    padding: $gutter/2;
    margin: $gutter/2 0;
    border-radius: 10px;
    border: 4px solid #a3d2f3;
    color: black;
    text-transform: uppercase;
    font-family: $font-tungsten-medium;
}

textarea {
    padding: $gutter/2;
    text-align: left;
    color: black;
    border-radius: 10px;
    border: 4px solid #a3d2f3;
    color: rgba(0,0,0,0.5);
    font-family: $font-tungsten-medium;
    text-transform: uppercase;
}