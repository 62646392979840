.price-item {
	background-color: white;
	margin: $gutter/2 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: $gutter;	
	border-radius: 10px;
	box-shadow: 5px 10px 20px rgba(0,0,0,0.5);

	img {
		width: 25%;
		margin-right: $gutter;
	}

	h3 {
		text-align: left;
		color: #144471;
		text-shadow: none;
		line-height: 1;
	}

	p {
		text-align: left;
		color: black;
		text-shadow: none;
	}
}