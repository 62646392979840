// ========================================================

// # GLOBAL-SETTINGS

// Global font-size 16px
$text-size: 16; 

// Global margins 20px
$gutter: 1rem; 

// Styling presets
$radius-border: 4px;

// Animation
$time-fast: .2s; 
$time: .4s; 
$time-slow: .6s;

// padding for content-box
$multiplyGutter: 15;

// Column devider for grid
$maxCols: 6;

// Views for media queries
$view-tablet-portrait: 30rem;
$view-tablet-landscape: 48rem;
$view-desktop: 60rem;