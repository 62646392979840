.dd-container {
	width: 100% !important;
	margin: $gutter 0;

	.dd-select {
		width: 100% !important;
		border-radius: 4px;
		border: 4px solid #a3d2f3;

		.dd-selected-text {
			left: 100px;
			position: relative;
		}

		img {
			max-width: 80px;
		}
	}

	.dd-options {
		width: 100% !important;
		position: relative;

		.dd-option {
			background-color: white;
			padding: $gutter/2 $gutter !important;
			margin: 0;

			img {
				margin-right: $gutter;
				height: 16px;
				max-width: 80px;
				position: absolute;
			}

			.dd-option-text {
				text-align: left;
				line-height: 16px !important;
				position: relative;
				left: 100px;
				font-family: $font-tungsten-medium;
			}
		}
	}
}

#seasonDropdown .dd-option-text {
	left: 0;
}

.dd-selected {
	padding: $gutter/2 $gutter !important;
	margin: 0;

	img {
		margin-right: $gutter;
		height: 16px;
		position: absolute;

	}
}

.dd-selected-text {
	color: black;
	text-align: left;
	margin: 0;
	line-height: 16px !important;
	font-family: $font-tungsten-medium;
}

.dd-pointer {
	right: $gutter !important;
}