.intro-title-animation {
	opacity: 0;
	position: relative;
}

.intro-title-flare {
	opacity: 0;
	transform: translateY(-50%);
	height: 700px;
	width: 100%;
	background-size: contain;
	background-image: url(../assets/images/flare.png);
	position: absolute;
	left: 100%;
	top: 50%;
}


#intro1,
#intro2,
#intro3,
#intro4 {
	position: relative;
}