.ng-animate {
  transition: all ease 0.4s;
}
 
.ng-enter {
  opacity: 0;
}
 
.ng-leave, 
.ng-enter.ng-enter-active {
  opacity: 1;
}
 
.ng-leave.ng-leave-active {
  opacity: 0;
}