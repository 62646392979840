// ========================================================

// # HELPERS

.list { list-style: none; list-style-image: none; padding-left: 0; }
.accessibility, .js .js-accessibility { clip: rect(1px, 1px, 1px, 1px); height: 1px; overflow: hidden; position: absolute; width: 1px; }
.accessibility:focus, .accessibility:active { clip: auto; height: auto; overflow: visible; position: fixed; width: auto; }
.group:before, .group:after { content: " "; display: table; }
.group:after { clear: both; }
.fieldset { @extend .group; }
.skip-to-main { left:-999px; position: absolute; top: auto; width: 1px; height: 1px; overflow: hidden; z-index: -9999; }
.skip-to-main:focus, .skip-to-main:active {color: #FFF; background-color: #000; left: 0; top: 0; overflow: auto; z-index: 999; }

.no-shadow {
	box-shadow: none;
	text-shadow: none;
}