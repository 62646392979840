.flex-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	min-height: calc(100vh - 2rem - 32px);
	position: relative;
	z-index: 2;
}

.flex-row {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;

	.btn {
		width: calc(50% - 0.5rem);
	}
}

.flex-stretch {
	flex-grow: 1;
	margin-bottom: 20px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}