.footer {
	position: relative;
	color: white;
	width: 100%;
	background-color: black;
	display: flex;
	justify-content: center;
	height: 30px;
	align-items: center;
	
	> a {
		position: relative;
		z-index: 1;
		text-decoration: none;
		color: inherit;
		text-transform: uppercase;
		font-size: 11px;
		width: initial;
		font-family: $font-khand-regular;

		&:after {
			margin: 0 $gutter/3;
			content: '|';
		}

		&:last-child {
			&:after {
				content: none;
			}
		}

		&:hover,
		&:active,
		&:focus {
			text-decoration: underline;
		}
	}
}