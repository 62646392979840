// ========================================================

// # NORMALIZE


html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, button, datalist, input, select, textarea, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figcaption, figure, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { background-position: center; background-repeat: no-repeat; border: 0; border-color: #000; border-style: solid; float: none; font: inherit; margin: 0; padding: 0; text-align: inherit; vertical-align: baseline; white-space: inherit; }
html, body { height: 100%; }
html, button, datalist, input, select, textarea { -webkit-font-smoothing: antialiased; }
html { overflow-y: scroll; }
body { background: #fff; -moz-text-size-adjust: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; word-wrap: break-word; }
table { border-collapse: collapse; border-spacing: 0; table-layout: fixed; }
th, td, textarea { vertical-align: top; }
th { font-weight: bold; text-align: left; }
blockquote, q { quotes: none; }
blockquote:before, blockquote:after, q:before, q:after { content: ""; content: none; }
pre, code, kbd, samp, button, datalist, input, select, textarea, .default { font-size: 1em; }
pre, code, kbd, samp { font-family: monospace, monospace; }
strong, b { font-weight: bold; }
em, i, dfn, span[lang], .special { font-style: italic; }
img, video, object, iframe, legend, datalist, input, select, textarea, label, article, aside, audio, canvas, details, figcaption, figure, footer, header, hgroup, marquee, menu, nav, section, svg, video, .block, .wrap, .group, .paragraph { display: block; }
img, video, object, iframe { max-width: 100%; }
img, video { height: auto; width: auto; }
ol, ul { list-style-position: outside; }
ol { list-style-type: decimal; }
ul { list-style-type: disc; }
sub, sup { font-size: 75%; line-height: 0; position: relative; }
sub { bottom: -.25em; }
sup { top: -.5em; }
legend { white-space: normal; }
label, .label { margin-bottom: .25em; }
textarea { min-height: 8em; overflow: auto; -moz-resize: vertical; resize: vertical; }
button, datalist, input, select, textarea, legend, label, .label { line-height: normal; }
button, datalist, input, select, textarea { border-radius: 0; border-width: 0; box-sizing: border-box; font-weight: normal; margin: 0; }
button, input[type="button"], input[type="submit"], input[type="checkbox"], input[type="file"], input[type="radio"], input[type="reset"], label, select, a { cursor: pointer; }
button, html input[type="button"], input[type="submit"], input[type="reset"] { -webkit-appearance: button; background-color: transparent; display: inline-block; font-weight: bold; overflow: visible; vertical-align: top; width: auto; }
button[disabled], input[disabled] { cursor: default; }
button::-moz-focus-inner, input::-moz-focus-inner { border-width: 0; padding: 0; }
datalist, input, select, textarea, fieldset, legend, table, .full { width: 100%; }
input[type="text"], input[type="number"], input[type="email"], input[type="tel"], input[type="url"], input[type="password"] { -moz-appearance: none; -webkit-appearance: none; } /* remove mobile input shadow */
input[type="checkbox"], input[type="radio"] { background-color: transparent; border-width: 0; box-shadow: none; float: left; margin: .075em .5em 0 0; padding: 0; width: auto; }
input[type="checkbox"]:focus, input[type="radio"]:focus { background-color: transparent; }
input[type="checkbox"] + label, input[type="radio"] + label { float: left; margin-bottom: 0; }
input[type="search"] { -webkit-appearance: textfield; }
input[type="search"]::-webkit-search-decoration { display: none; }
select { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
:focus, :active { transition: none; }
:focus { outline: none; }
