// ========================================================
// # PAGE

.page {
    position: relative;
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
}

.page__content {
    padding-top: 78px;
    backface-visibility: hidden;    

    -ms-overflow-style: none;  // IE 10+
    overflow: -moz-scrollbars-none !important;  // Firefox

    &::-webkit-scrollbar { 
        display: none;  // Safari and Chrome
    }
}

.page-bg {
    background-image: url(../assets/images/fox-stadion.png);
    position: absolute;
    background-size: cover;
    height: calc(100vh + 100px);
    width: calc(100vw + 100px);
    margin-left: -50px;
    margin-top: -50px;
    background-position-y: 80%;
}

// ========================================================
// DESKTOP
// ========================================================

@media screen and (min-width: 64em) {
    .page__content {
        padding-top: 130px;
    }
}
