.fox-logo {
	width: 70%;
	max-width: 230px;
	margin-bottom: -$gutter;
}

.prices-image {
	width: 150%;
	max-width: 700px;
	position: relative;	
	z-index: -1;
	margin: -120px 0;
}

.bedankt-image {
	width: 100%;
	position: relative;
	// margin-top: 40px;
	z-index: -1;

	&:before {
		content: 'bedankt';
		position: absolute;
		font-family: $font-sports-fox;
		width: 100%;
		font-size: 60px;
		text-align: center;
		text-shadow: 0px 0px 20px rgba(103, 186,255,0.5);
		z-index: -1;
		text-transform: uppercase;
		bottom: 80%;
		left: 0;

	}
}