// ========================================================

// #COLORS

$color-text: #ffffff;
$color-primary: #0078d7;

// Form palette
$color-input: #eaeaea; // Input background color
$color-input-focus: #ccc; // Input focus background color
$color-error: #CC0000;


// Social palette
$social-facebook: #3b5998;
$social-linkedin: #0077B5;
$social-twitter: #55acee;
